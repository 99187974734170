import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Particle from './../components/Particle'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import BandCampIconLight from '../images/Svg/bandcamp-light.svg'
import BandCampIconDark from '../images/Svg/bandcamp-dark.svg'
import Loading from './../components/Loading'

function HomePage({ theme }) {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  return (
    !swLoaded ? <Loading /> : <HomePageStyled>
      <Particle />
      <div className="typography">
        <h1>Salut, je suis <span>Stéphane Bressani</span></h1>
        <p>
            Je suis un développeur de logiciel pour ordinateur, web et mobile (iOs).<br/>
            Je compose également de la musique électronique.
        </p>

        <div className="social-icons">
          <a href="https://github.com/stephaneworkspace" target='__blank'><GitHubIcon /></a>
          <a href="https://www.linkedin.com/in/stephane-bressani-92b916245/" target='__blank'><LinkedInIcon /></a>
          <a href="https://electrips.bandcamp.com/" target='__blank'>
            <img
              src={theme === 'light-mode' ? BandCampIconLight : BandCampIconDark} className='icon-svg' alt='Bandcamp'
            />
          </a>
        </div>
      </div>
    </HomePageStyled>
  )
}

const HomePageStyled = styled.div`
  /* fade */
  -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
  animation: my-fade 1s;
  //
  width: 100%;
  height: 90vh;
  position: relative;
  .typography{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align:center;
      width: 80%;
      @media screen and (max-width:600px){
          width: 100%;
      }
      p{
          padding:0.5rem 0 1rem 0;
          @media screen and (max-width:600px){
              font-size:0.8rem;
          }
      }
  }
  .social-icons{
      display:flex;
      align-items: center;
      justify-content: center;
      a{
          display:flex;
          align-items: center;
          justify-content: center;
          padding:0.7rem;
          border-radius:50%;
          border:2px solid var(--border-color);
          cursor: pointer;
          transition: all 0.4s ease-in-out;
          margin:0 .5rem;
          @media screen and (max-width:625px){
              margin:0 .1rem;
              padding:0.5rem;
          }
          svg{
              color:var(--font-color);
              font-size:1.4rem;
              @media screen and (max-width:625px){
                  font-size:1.1rem;
              }
          }
          &:hover{
              border: 2px solid var(--primary-color);
              opacity:0.5;
              &:hover svg{
                  // color:var(--primary-color);
                  opacity:0.5;
              }
          }
      }

  }
`

export default HomePage
