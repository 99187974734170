import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from './../components/Title'
import { InnerLayout } from '../styles/Layouts'
import image from './../images/Avatar/stephane.png'
import Loading from './../components/Loading'
// import PrimaryButton from './../components/PrimaryButton';
// import Carousel from 'react-elastic-carousel';
// import TestimonialItem from './../components/TestimonialItem';
// import client1 from './../images/Testimonials/client.jpg';
// import client2 from './../images/Testimonials/client.jpg';
// import client3 from './../images/Testimonials/client.jpg';
// import CvPdf from '../pdf/cv.pdf';

/*
                    <a
                        href={CvPdf}
                        download='Cv-Stéphane-Bressani'
                        target='_blank'
                        rel='noreferrer'
                        >
                        <PrimaryButton btn={'Télécharger Cv'} />
                    </a>
            <div className="testimonials">
                <Title title={'Testimonials'} span={''} />
                <InnerLayout>
                    <Carousel>
                        <TestimonialItem text={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, est quae? Rem natus sunt vitae esse eum possimus nulla quam'} img={client1} />
                        <TestimonialItem text={' Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, est quae? Rem natus sunt vitae esse eum possimus nulla quam'} img={client2} />
                        <TestimonialItem text={' Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, est quae? Rem natus sunt vitae esse eum possimus nulla quam'} img={client3} />
                    </Carousel>
                </InnerLayout>
            </div>
 */
function AboutPage() {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  return (
    !swLoaded ? <Loading /> : <AboutPageStyled>
      {/* about Start Here */}
      <Title title={'A'} span={'Propos'} />
      <InnerLayout className="about-section">
        <div className="image-part">
          <img className="image-no-border" src={image} alt="" />
        </div>
        <div className="info-part">
          <h4>Qui suis-je ?</h4>
          <h3>Je suis <span>Stéphane Bressani</span></h3>
          <p>Je suis un développeur d'application pour ordinateur (Mac et Windows), web et mobile (iOs). J'aime également m'occuper du design de mes applications. Je suis aussi compositeur de musique électronique.</p>
          <div className="information">
            <ul>
              <li>Prénom et nom</li>
              <li>Age</li>
              <li>Langues</li>
              <li>IA</li>
              <li>Dév. system</li>
              <li>Dév. back-end</li>
              <li>Dév. front-end</li>
              <li>Dév. mobile</li>
              <li>Bureautique</li>
              <li>Code design</li>
              <li>Admin serveur</li>
              <li>Design</li>
              <li>Composition</li>
              <li>Retouche vidéo</li>
            </ul>
            <ul className="details">
              <li>Stéphane Bressani</li>
              <li>38 ans</li>
              <li>Français, Allemand B1, Anglais B2</li>
              <li>GPT-4</li>
              <li>Rust, C++, Python, Cobol</li>
              <li>Rails, Laravel, FastCGI</li>
              <li>React, Vue.js, Angular</li>
              <li>Swift</li>
              <li>Office 365</li>
              <li>Sensible au code design</li>
              <li>Arch, (Debian, Ubuntu) et Apache, Docker, Cron...</li>
              <li>Corel Draw</li>
              <li>Ableton Live, Cubase, Modular, VST</li>
              <li>Final Cut Pro</li>
            </ul>
          </div>
        </div>
      </InnerLayout>
      {/* About End Here */}

      {/* Licenses de ce site Start Here */}
      <div className="apropos">
        <Title title={'Licenses'} span={''} />
        <InnerLayout>
            Une partie du design du logo en haut à gauche a été crée à partir d'une modification de l'image de onfocus sur <a href='https://fr.freepik.com/' target='_blank'>Freepik</a>.<br/>
            Le design de ma photo sur cette page a été créer à partir de l'image de vikayatskina sur <a href='https://fr.freepik.com/' target='_blank'>Freepik</a>.
        </InnerLayout>
      </div>
      {/* Licenses de ce site End Here */}

      {/* Testimonials Start Here */}
      {/* Testimonials End Here */}
    </AboutPageStyled>
  )
}

const AboutPageStyled = styled.div`
  /* fade */
  -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
  animation: my-fade 1s;
  .about-section{
      display:grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap:.8rem;
      @media screen and (max-width:800px){
          grid-template-columns: repeat(1, 1fr);
          grid-gap:1.5rem;
      }
      .image-part{
          height:100%;
          width:100%;
          img{
              width:90%;
              height:90%;
              border:2px solid var(--border-color);
          }
      }
      .info-part{
          h4{
              color:var(--primary-color);
              font-size:1.3rem;
          }
          h3{
              margin:1rem 0;
              font-size:1.9rem;
              span{
                  color:var(--primary-color);
              }
          }
          p{
              font-size:.9rem;
          }
          .information{
              margin:.5rem 0;
              width:90%;
              display:flex;
              justify-content: space-between;
              @media screen and (max-width:500px){
                  width:100%;
              }
              ul{
                  li{
                      margin:.5rem 0;
                      @media screen and (max-width:500px){
                          margin:1rem 0;
                          font-size:.9rem;
                      }
                  }
              }
              .details{
                  color:var(--paragraph-color);
              }
          }

      }
  }

  .rec-arrow{
      background-color: var(--border-color);
      &:hover{
          background-color: var(--primary-color);
      }
      &:active,&:focus{
          background-color: var(--primary-color);
      }
  }
  .rec-dot_active{
      box-shadow:none;
      background-color:var(--primary-color);
  }
  @media screen and (max-width:500px){
      .rec.rec-arrow{
          display:none;
      }
  }
`

export default AboutPage
