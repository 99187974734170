import bressart from '../images/Portfolio/bressart.png'
// import ukuleleHelperWeb from '../images/Portfolio/ukulele_helper_web.png'
// import ukuleleHelperAppstore from '../images/Portfolio/ukulele_helper_appstore.png'
import astronumerologieRepo from '../images/Portfolio/astronumerologie-repo.png'
import electripsImmersedInSound from '../images/Portfolio/electrips_immersed_in_sound.png'
// import astrologieTraditionnelleNet from '../images/Portfolio/astrologie-traditionnelle.net.png'
import octaSplit from '../images/Portfolio/octa_split.png'
import waveGlitch from '../images/Portfolio/wave_glitch.png'
import SwiftPackage from '../images/Portfolio/SwiftPackage.png'

const Portfolio = [
  {
    id: 1,
    title: 'Electrips - Immersed in sound',
    subTitle: 'Mon premier album de musique électronique',
    category: 'Musique électronique',
    image: electripsImmersedInSound,
    link1: '',
    link2: '',
    link3: 'https://electrips.bandcamp.com/album/immersed-in-sound'
  },
  {
    id: 2,
    title: 'Bressart',
    subTitle: 'Site web d\'artiste mangaka',
    category: 'Site web',
    image: bressart,
    link1: '',
    link2: 'https://www.bressart.ch/',
    link3: ''
  },
  {
    id: 6,
    category: 'Mobile iOs',
    title: 'Astro-numérologie',
    subTitle: 'Code source disponible pour compiler et installer un programme d\'astronumérologie encore en cours de developpement sur iOs. Programmé en Swift et C++',
    image: astronumerologieRepo,
    link1: 'https://github.com/stephaneworkspace/astronumerologie',
    link2: '',
    link3: ''
  },
  {
    id: 7,
    category: 'Freeware',
    title: 'OctaSplit (Mac/Win/Linux)',
    subTitle: 'OctaSplit est un outil pratique développé en C++ pour Mac, Windows et Linux, destiné aux producteurs de musique. Il permet de prendre un fichier généré par un logiciel de musique assistée par ordinateur et de le découper en petits morceaux prêts à être utilisés dans l\'Octatrack d\'Elektron.',
    image: octaSplit,
    link1: 'https://github.com/stephaneworkspace/OctaSplit',
    link2: '',
    link3: ''
  },
  {
    id: 8,
    category: 'Freeware',
    title: 'WaveGlitch (Mac/Linux)',
    subTitle: 'WaveGlitch, disponible pour Mac et Linux, transforme vos fichiers audio en échantillons subdivisés, idéaux pour la création d\'effets glitch, allant de 1/1 à 1/128.',
    image: waveGlitch,
    link1: 'https://github.com/stephaneworkspace/WaveGlitch_BressaniDev',
    link2: '',
    link3: ''
  },
  {
    id: 9,
    category: 'Mobile iOs',
    title: 'DeviceInterfaceDetector',
    subTitle: 'Swift Package de détection de l\'appareil iOS, iPad ou Mac Silicon afin de créer l\'UI intelligemment.',
    image: SwiftPackage,
    link1: 'https://github.com/stephaneworkspace/DeviceInterfaceDetector/tree/v1_0',
    link2: '',
    link3: ''
  },
  {
    id: 10,
    category: 'Mobile iOs',
    title: 'CountriesCitiesReader',
    subTitle: 'Countries Cities Reader est une bibliothèque Swift conçue pour fournir un accès simplifié aux informations détaillées sur les pays du monde, leurs villes, ainsi que les drapeaux des pays. Il intègre des données provenant de sources externes et offre des fonctionnalités pour interroger ces données de manière efficace et intuitive.',
    image: SwiftPackage,
    link1: 'https://github.com/stephaneworkspace/CountriesCitiesReader/releases/tag/v1.0',
    link2: '',
    link3: ''
  }
]

export default Portfolio
